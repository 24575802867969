/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    div: "div",
    em: "em",
    strong: "strong"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<span\n      class=\"gatsby-resp-image-wrapper\"\n      style=\"position: relative; display: block; margin-left: auto; margin-right: auto; max-width: 620px; \"\n    >\n      <span\n    class=\"gatsby-resp-image-background-image\"\n    style=\"padding-bottom: 67.46987951807229%; position: relative; bottom: 0; left: 0; background-image: url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAAUD/8QAFQEBAQAAAAAAAAAAAAAAAAAAAAH/2gAMAwEAAhADEAAAAdacmomwX//EABsQAAICAwEAAAAAAAAAAAAAAAECAAMEESIy/9oACAEBAAEFAsYsci7wo0tdhMSrpdz/xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAaEAACAwEBAAAAAAAAAAAAAAAAAQIRIVES/9oACAEBAAY/AndlJ6xLhxinJ+pGn//EABoQAQEBAQEBAQAAAAAAAAAAAAERACFRMUH/2gAIAQEAAT8hoVJavmTUXE1sViVwInT6m6HA4+a40Pfw3//aAAwDAQACAAMAAAAQZw//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/ED//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/ED//xAAcEAEAAwADAQEAAAAAAAAAAAABABEhMUFxUZH/2gAIAQEAAT8Q1XbZssh55B9U2bbGXEEmtZbOKOHYrSHyJkUF+ESb1wtM/Wf/2Q=='); background-size: cover; display: block;\"\n  ></span>\n  <img\n        class=\"gatsby-resp-image-image\"\n        alt=\"Origin of Lbs abbreviation for pounds\"\n        title=\"Origin of Lbs abbreviation for pounds\"\n        src=\"/static/16c93ac52496290033b7cf3377380a4b/e83c5/libra-scales.jpg\"\n        srcset=\"/static/16c93ac52496290033b7cf3377380a4b/4d5fb/libra-scales.jpg 166w,\n/static/16c93ac52496290033b7cf3377380a4b/558f0/libra-scales.jpg 333w,\n/static/16c93ac52496290033b7cf3377380a4b/e83c5/libra-scales.jpg 620w\"\n        sizes=\"(max-width: 620px) 100vw, 620px\"\n        style=\"width:100%;height:100%;margin:0;vertical-align:middle;position:absolute;top:0;left:0;\"\n        loading=\"lazy\"\n        decoding=\"async\"\n      />\n    </span>"
    }
  })), "\n", React.createElement(_components.p, null, "Most of the time when a word is abbreviated, it's plain to see how one would designate such an abbreviation based on the original word."), "\n", React.createElement(_components.p, null, "For example, ", React.createElement(_components.em, null, "altitude"), " turns into ", React.createElement(_components.em, null, "alt."), ", ", React.createElement(_components.em, null, "gallons"), " turns into ", React.createElement(_components.em, null, "gal."), ", and ", React.createElement(_components.em, null, "hours"), " turns into hrs.*."), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "But how does one get \"lbs\" from \"pounds\"?")), "\n", React.createElement(_components.p, null, "It is the case that the unit of mass known as \"pounds\" is derived from an ancient Roman unit of mass called the \"libra\" which was about ", React.createElement(_components.strong, null, "327 grams"), "."), "\n", React.createElement(_components.p, null, "Libra refers to scales or a balance which would have been a common sight at any given marketplace."), "\n", React.createElement(_components.p, null, "So from \"pounds\" we get \"lbs\" in a round about way, of course."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
